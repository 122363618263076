export const S3_URL_PREFIX =
  "https://website-landing-page-np.s3.ap-south-1.amazonaws.com/";

export const assetPath = {
  EsopsBuyBackMainImg: `${S3_URL_PREFIX}20241114071856_EsopsBuyBackMainImg.png`,
  CultureImg1: `${S3_URL_PREFIX}20241114073359_1.jpeg`,
  CultureImg2: `${S3_URL_PREFIX}20241114073400_2.1.jpg`,
  CultureImg3: `${S3_URL_PREFIX}20241114073401_2.jpeg`,
  CultureImg4: `${S3_URL_PREFIX}20241114073405_3.1.png`,
  CultureImg5: `${S3_URL_PREFIX}20241114073406_3.jpeg`,
  CultureImg6: `${S3_URL_PREFIX}20241114073406_4.jpeg`,
  CultureImg7: `${S3_URL_PREFIX}20241114073407_5.jpeg`,
  CultureImg8: `${S3_URL_PREFIX}20241114073408_6.jpeg`,
  CultureImg9: `${S3_URL_PREFIX}7.jpg`,
  CultureImg10: `${S3_URL_PREFIX}20241114073410_8.jpeg`,
  CultureImg11: `${S3_URL_PREFIX}20241114073412_9.jpg`,
  CultureImg12: `${S3_URL_PREFIX}20241114073413_10.jpg`,
  CultureImg13: `${S3_URL_PREFIX}20241114073414_11.jpg`,
  CultureImg14: `${S3_URL_PREFIX}20241114073414_12.jpg`,
  CultureImg15: `${S3_URL_PREFIX}13.jpg`,
  CultureImg16: `${S3_URL_PREFIX}14.jpg`,
  CultureImg17: `${S3_URL_PREFIX}20241114094152_15.jpg`,
  CultureImg18: `${S3_URL_PREFIX}20241114073426_BloodDonationCamp.jpg`,
};

export const caseStudyAssetPaths = {
  TitagarhImg: `${S3_URL_PREFIX}20241216073057_TitagarhCaseStudy.png`,
  TitagarhPdf: `${S3_URL_PREFIX}NP_2408_Case+Study+Titagarh.pdf`,
  FoundryDivisionPdf: `${S3_URL_PREFIX}nowCaseStudy.pdf`,
  FoundryDivisionImg: `${S3_URL_PREFIX}20250116092348_FoundryDivisionCaseStudyImg.png`,
};
