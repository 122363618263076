import { AnimatePresence } from "framer-motion";
import React from "react";
import Container from "../../Components/General/Container";
import { caseStudyAssetPaths } from "../../assets/s3Uploads";
import RightArrow from "../../assets/images/icons/rgt_arrow_vector_bl.png";
import styles from "./CaseStudy.module.css";

const CaseStudy = () => {
  const case_studies = [
    // {
    //   img_url: caseStudyAssetPaths.TitagarhImg,
    //   subtitle: "Digitization of Titagarh Rail Systems Limited",
    //   publish_date: "1st May, 2023",
    //   description:
    //     "This case study showcases how MetalCloud’s platform and NowPurchase’s B2B marketplace helped Titagarh streamline its procurement and manufacturing, enabling it to meet growing demand and stay competitive in a fast-changing market.",
    //   redirection_link: caseStudyAssetPaths.TitagarhPdf,
    // },
    {
      img_url: caseStudyAssetPaths.FoundryDivisionImg,
      subtitle: "Steel Foundry Achieves 5% Profit and Productivity Gain",
      publish_date: "17th Jan, 2025",
      description:
        "This case study showcases how MetalCloud's platform transformed a Pune-based steel foundry's operations, boosting profitability by 5%, cutting production costs by 4%, and enhancing efficiency with automated processes, real-time insights, and improved quality control.",
      redirection_link: caseStudyAssetPaths.FoundryDivisionPdf,
    },
  ];
  return (
    <AnimatePresence>
      <Container>
        <div className={styles.titleContainer}>Case Studies</div>
        <div className={styles.caseStudyContainer}>
          {case_studies.map(
            ({ img_url, description, subtitle, redirection_link }) => (
              <div className={styles.caseStudyBlock}>
                <img
                  className={styles.caseStudyImages}
                  src={img_url}
                  alt="CaseStudyImage"
                />
                <div className={styles.content}>
                  {/* <div
                    className={styles.publishDate}
                  >{`Published on : ${publish_date}`}</div> */}
                  <div className={styles.subtitleText}>{subtitle}</div>
                  <div className={styles.description}>{description}</div>
                  <a
                    href={redirection_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.mediaRedirectBtn}
                  >
                    <span className={styles.readMoreText}>Read More</span>
                    <img src={RightArrow} />
                  </a>
                </div>
              </div>
            )
          )}
        </div>
      </Container>
    </AnimatePresence>
  );
};

export default CaseStudy;
