import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Container from "../../../Components/General/Container";
import { CategoryTabs } from "./CategoryTabs";
import ProductGrid from "./ProductGrid";
import ProductEnquiry from "./ProductEnquiry";
import { useLocation } from "react-router-dom";

function OneStopSolutions() {
  const aosRef = useRef(null);
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);
  const [enquiredProduct, setEnquiredProduct] = useState();
  const [activeCategory, setActiveCategory] = useState("metal-scrap");

  const categories = [
    { id: "metal-scrap", label: "Metal Scrap" },
    { id: "carbon-additives", label: "Carbon Additives" },
    { id: "nodularizers", label: "Nodularizers" },
    { id: "inoculant", label: "Inoculant" },
    { id: "ferro-alloys", label: "Ferro Alloys" },
    { id: "noble-ferro-alloys", label: "Noble Ferro Alloys" },
    { id: "pig-iron", label: "Pig Iron" },
    { id: "virgin-metals", label: "Virgin Metals & Foundry Essentials" },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryFromQuery = searchParams.get("category");
    if (categoryFromQuery) {
      setActiveCategory(categoryFromQuery);
    }
  }, [location.search]);

  const handleProductEnquiry = (product) => {
    setModalOpen((prev) => !prev);
    setEnquiredProduct(product);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("ltr");
            // entry.target.classList.remove("ltr-out");
          } else {
            // entry.target.classList.add("ltr-out");
            // entry.target.classList.remove("ltr");
          }
        });
      },
      {
        threshold: 0.3,
        // rootMargin: "-10px",
      }
    );

    if (aosRef.current) {
      observer.observe(aosRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (aosRef.current) {
        observer.unobserve(aosRef.current);
      }
    };
  }, []);
  return (
    <>
      <div
        ref={aosRef}
        className="solutionSec animated-section section aosAnim "
        id="sec36"
      >
        <div className="scrollSection">
          <Container>
            <div className="solutionHead d-flex align-items-center justify-content-between">
              <div className="sechead keyHead mb-8 aosAnim ">
                <h3>
                  <span>One-stop solution</span> for all
                  <br />
                  your procurement needs
                </h3>
                <p>
                  To view all available raw materials &amp; check prices, place
                  an enquiry and someone from our team will get back to you
                </p>
              </div>
            </div>
            <CategoryTabs
              categories={categories}
              activeCategory={activeCategory}
              onCategoryChange={setActiveCategory}
            />
            <ProductGrid
              activeCategory={activeCategory}
              onEnquire={handleProductEnquiry}
            />
            <ProductEnquiry
              enquiredProduct={enquiredProduct}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
            />
          </Container>
        </div>
      </div>
    </>
  );
}

export default OneStopSolutions;
